import * as SVGS from "./svgs/svgs";
import css from "./TitleSection.module.scss";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import {
  ButtonWithBackground,
  ButtonWithOutline,
} from "../common/Buttons";

export const TitleSection = () => {
  return (
    <div className={css.backgroundContainer}>
      <div className={css.background}>
        <HeroSection />
        <BackgroundElement svg={<SVGS.RightClouds />} className={css.rightClouds} />
        <BackgroundElement svg={<SVGS.LeftClouds />} className={css.leftClouds} />
        <BackgroundElement svg={<SVGS.Moon />} className={css.moon} />
        <BackgroundElement svg={<SVGS.MoonSurface />} className={css.moonSurface} />
        <BackgroundElement svg={<SVGS.MovingStars />} className={css.movingStars} />
        <BackgroundElement svg={<SVGS.StationaryStars />} className={css.stationaryStars} />
      </div>
    </div>
  );
};

export const BackgroundElement = ({
  svg,
  className,
}: {
  svg: JSX.Element;
  className: string;
}) => {
  return <div className={classNames(css.svgContainer, className)}>{svg}</div>;
};

const HeroSection: React.FC = () => {
  const navigate = useNavigate();
  const goToAboutUsPage = () => navigate("/about-us");
  return (
    <div className={css.titleContainer}>
      <h1 className={css.titleText}>Sponsor Us</h1>
      <div className={css.descriptionText}>
        <p>
          LA Hacks can't happen without the support of our wonderful sponsors.
          As we celebrate our 12th year, we're ready to make our event bigger
          and better than ever.
        </p>
        <p>
          We're all about fostering a community, and we'd love to bring you into
          the LA Hacks family!
        </p>
      </div>
      <div className={css.buttonContainer}>
        <a href="#sponsor-us">
          <ButtonWithBackground>Sponsor Us</ButtonWithBackground>
        </a>
        <a href="/about-us">
          <ButtonWithOutline>About LA Hacks</ButtonWithOutline>
        </a>
      </div>
    </div>
  );
};
