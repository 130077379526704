import classNames from "classnames";
import css from "./Buttons.module.scss";

export const ButtonNoBackground: React.FC = ({ children }) => {
  return (
    <button className={classNames(css.buttonBase, css.buttonNoBackground)}>
      {children}
    </button>
  );
};

export const ButtonWithOutline: React.FC = ({ children }) => {
  return (
    <button className={classNames(css.buttonBase, css.buttonWithOutline)}>
      {children}
    </button>
  );
};

export const ButtonWithBackground: React.FC = ({ children }) => {
  return (
    <button className={(css.buttonBase, css.buttonWithBackground)}>
      {children}
    </button>
  );
};

export const ButtonMinimal: React.FC<{
  onClick: () => void;
  className?: string;
}> = ({ children, onClick, className }) => {
  return (
    <button
      className={classNames(css.buttonBase, css.buttonMinimal, className)}
      onClick={onClick}
    >
      {children}
    </button>
  );
};
