import * as SVGS from "./svgs/svgs";
import css from "./TitleSection.module.scss";
import classNames from "classnames";
import { RightArrow } from "./svgs/svgs";

export const TitleSection = () => {
  return (
    <div className={css.backgroundContainer}>
      <div className={css.background}>
        <BackgroundElement svg={<SVGS.Ribbing />} className={css.ribbing} />
        <HeroSection />
        <BackgroundElement svg={<SVGS.Cloud1 />} className={css.cloud1} />
        <BackgroundElement svg={<SVGS.Cloud2 />} className={css.cloud2} />
        <BackgroundElement svg={<SVGS.Lake />} className={css.lake} />
        <BackgroundElement svg={<SVGS.Star1 />} className={css.star1} />
        <BackgroundElement svg={<SVGS.Star2 />} className={css.star2} />
        <BackgroundElement svg={<SVGS.Star3 />} className={css.star3} />
        <BackgroundElement svg={<SVGS.Star4 />} className={css.star4} />
        <BackgroundElement svg={<SVGS.Star5 />} className={css.star5} />
        <BackgroundElement svg={<SVGS.Cloud4 />} className={css.cloud4} />
        <BackgroundElement svg={<SVGS.Cloud3 />} className={css.cloud3} />
      </div>
    </div>
  );
};

const BackgroundElement = ({
  svg,
  className,
}: {
  svg: JSX.Element;
  className: string;
}) => {
  return <div className={classNames(css.svgContainer, className)}>{svg}</div>;
};

const HeroSection: React.FC = () => {
  return (
    <div className={css.titleContainer}>
      <h6>April 2025</h6>
      <h1 className={css.titleText}>LA HACKS 2025</h1>
      <div className={css.emailInputContainer}>
        <input
          placeholder="Enter email to stay updated"
          className={css.emailInput}
        ></input>
        <button>
          <RightArrow />
        </button>
      </div>
    </div>
  );
};
