import { useCallback, useEffect, useState } from "react";
import {
  SHEET_NAMES,
  SheetRowStructureMap,
  MailingSiteData,
  PhotoNameToIdMap,
  mapRowsToData,
} from "../googleSheetsTypes";

// Get mapping of photo names to file IDs
async function getPhotoNameToIdMap() {
  const folderId = "1vRe8RO22wOsZ3scvs0NB8vjmDrRG3vvd";
  const apiKey = process.env.REACT_APP_GOOGLE_API_KEY;
  const url = `https://www.googleapis.com/drive/v3/files?q='${folderId}'+in+parents&key=${apiKey}`;

  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    const nameToId: PhotoNameToIdMap = data.files.reduce(
      (acc: { [key: string]: string }, file: { id: string; name: string }) => {
        acc[file.name] = file.id;
        return acc;
      },
      {}
    );
    return nameToId;
  } catch (error) {
    console.error("Error fetching files:", error);
    return {};
  }
}

interface GoogleSheetsApiResponse {
  valueRanges: {
    range: string;
    majorDimension: string;
    values: string[][];
  }[];
}

async function getGoogleSheetsData(): Promise<GoogleSheetsApiResponse> {
  const apiKey = process.env.REACT_APP_GOOGLE_API_KEY;
  const sheetId = "1pVepk2UoSSX0qZxFMZaHps5wsCM5uPb4OTkUENIPpLM";
  const ranges = SHEET_NAMES.map((sheetName) => `${sheetName}`).join(
    "&ranges="
  );
  const url = `https://sheets.googleapis.com/v4/spreadsheets/${sheetId}/values:batchGet?ranges=${ranges}&key=${apiKey}`;
  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching files:", error);
    return { valueRanges: [] };
  }
}

function parseGoogleSheetsData(
  data: GoogleSheetsApiResponse,
  nameToIdMap: PhotoNameToIdMap
) {
  const sheetNameToData: { sheetName: string; rows: string[][] }[] = [];
  data.valueRanges.forEach((rangeData) => {
    const sheetNameInQuotes = rangeData.range.split("!")[0];
    const sheetName =
      sheetNameInQuotes[0] === "'"
        ? sheetNameInQuotes.slice(1, -1)
        : sheetNameInQuotes;
    const rows = rangeData.values.slice(1); // Exclude the header row
    sheetNameToData.push({ sheetName, rows });
  });
  return sheetNameToData.reduce((acc, data) => {
    const sheetName = data.sheetName as keyof SheetRowStructureMap;
    // @ts-ignore
    acc[sheetName] = mapRowsToData(sheetName, data.rows, nameToIdMap);
    return acc;
  }, {} as MailingSiteData);
}

async function fetchGalleryInformation(): Promise<MailingSiteData> {
  const data = await getGoogleSheetsData();
  const nameToIdMap = await getPhotoNameToIdMap();
  return parseGoogleSheetsData(data, nameToIdMap);
}

// Hook to get gallery information by sheet name
export const useGetGalleryInformation = () => {
  const [galleryData, setGalleryData] = useState<MailingSiteData | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  // Fetch the PhotoNameToIdMap and gallery data when the component mounts
  useEffect(() => {
    const fetchInitialData = async () => {
      setLoading(true);
      try {
        setGalleryData(await fetchGalleryInformation());
      } catch (err) {
        console.error("Error fetching initial data:", err);
      } finally {
        setLoading(false);
      }
    };

    fetchInitialData();
  }, []);

  // Function to get photo data by sheet name
  const getSheetData = useCallback(
    <SheetName extends keyof MailingSiteData>(
      sheetName: SheetName,
      maxSize: number
    ): MailingSiteData[SheetName] => {
      if (!galleryData) {
        return [];
      }
      const sheetData = galleryData[sheetName];
      if (!sheetData) {
        console.error(
          `Sheet "${sheetName}" not found in retrieved data. Did you add it to the TEAMS const?`
        );
        return [];
      }
      return sheetData.slice(0, maxSize) as MailingSiteData[SheetName];
    },
    [galleryData]
  );

  return { getSheetData, loading };
};
