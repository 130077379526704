import "./WinningProjects.scss";
import SoundscapePhoto from "../static/sponsor/soundscapePhoto.jpg"
import FormFixerPhoto from "../static/sponsor/formFixerPhoto.jpg"
import MarkedDownPhoto from "../static/sponsor/markedDownPhoto.jpg"
import TrophyIcon from "../static/sponsor/trophy.png"
import ChallengeIcon from "../static/sponsor/challenge.png"

export const WinningProjects = () => {
    return (
        <div className="winning-projects-container">
        <h2>Past winning projects</h2>
        <div className="project-cards-container">
          <div className="card">
            <div className="card-inner">
              <div className="card-front">
                <img src={SoundscapePhoto} alt="Soundscape" />
                <label>Soundscape</label>
              </div>
              <div className="card-back">
                <div>
                    <h3>Soundscape</h3>
                    <div className="rank-info-container">
                        <div className="rank-info">
                            <img src={TrophyIcon} alt="Trophy Icon" />
                            <p>3rd Place</p>
                        </div>
                        <div className="rank-info">
                            <img src={ChallengeIcon} alt="Challenge Icon" />
                            <p>Intel Company Challenge</p>
                        </div>
                    </div>
                </div>
                <label>LA Hacks 2024</label>
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-inner">
              <div className="card-front">
                <img src={FormFixerPhoto} alt="FormFixer" />
                <label>FormFixer</label>
              </div>
              <div className="card-back">
                <div>
                    <h3>FormFixer</h3>
                    <div className="rank-info-container">
                        <div className="rank-info">
                            <img src={TrophyIcon} alt="Trophy Icon" />
                            <p>2rd Place</p>
                        </div>
                    </div>
                </div>
                <label>LA Hacks 2024</label>
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-inner">
              <div className="card-front">
                <img src={MarkedDownPhoto} alt="MarkedDown" />
                <label>MarkedDown</label>
              </div>
              <div className="card-back">
                <div>
                    <h3>MarkedDown</h3>
                    <div className="rank-info-container">
                        <div className="rank-info">
                            <img src={TrophyIcon} alt="Trophy Icon" />
                            <p>1st Place</p>
                        </div>
                    </div>
                </div>
                <label>LA Hacks 2024</label>
              </div>
            </div>
          </div>
        </div>
      </div>
      
    );
  };
  