import { useMemo } from "react";
import { GalleryProps } from "../googleSheetsTypes";
import css from "./SponsorLogos.module.scss";

export const SponsorLogos: React.FC<GalleryProps> = ({ getSheetData }) => {
  const sponsorLogoImageIds = useMemo(
    () => getSheetData("Sponsors", 100).map((photo) => photo.id),
    [getSheetData]
  );
  return (
    <div className={css.sponsorLogoContainer}>
      {sponsorLogoImageIds.map((id) => (
        <SponsorLogo key={id} id={id} />
      ))}
    </div>
  );
};

const SponsorLogo: React.FC<{ id: string }> = ({ id }) => {
  return (
    <div className={css.logo}>
      <img
        src={`https://drive.google.com/thumbnail?id=${id}&sz=w1000`}
        alt="sponsor logo"
      />
    </div>
  );
};
