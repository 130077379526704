import css from "./PageWrapper.module.scss";
import { NavBar } from "./NavBar";
import { Footer } from "./Footer";
import classNames from "classnames";

interface PageWrapperProps {
  children: React.ReactNode;
  background?: string;
}

export const PageWrapper: React.FC<PageWrapperProps> = ({
  children,
  background,
}) => {
  return (
    <div
      className={css.pageWrapper}
      style={{
        background: background,
      }}
    >
      <NavBar />
      {children}
      <Footer />
    </div>
  );
};

export const ContentContainer: React.FC<{
  children: React.ReactNode;
  className?: string;
}> = ({ children, className }) => {
  return (
    <div className={classNames(css.contentContainer, className)}>
      {children}
    </div>
  );
};

export const SectionContainer: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  return <div className={css.sectionContainer}>{children}</div>;
};
