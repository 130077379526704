import { useEffect, useState } from 'react';
import css from "./Footer.module.scss";
import InstagramIcon from "../static/sponsor/instagram.png";
import TwitterIcon from "../static/sponsor/twitter.png";
import FacebookIcon from "../static/sponsor/facebook.png";
import HeartEmoji from "../static/sponsor/heartEmoji.png";
import StarEmoji from "../static/sponsor/starEmoji.png";

const useScreenWidth = (breakpoint: number): boolean => {
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth <= breakpoint);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= breakpoint);

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, [breakpoint]);

  return isMobile;
};

export const Footer: React.FC = () => {
  const isMobile = useScreenWidth(768);
  return (
    <div className={css.footer}>
      <div className={css.links}>
        <a 
          href="https://mlh.io/code-of-conduct" className={css.footerLink}>MLH Code of Conduct</a>
        <span className={css.dot}>•</span>
        <a href={isMobile ? "#app-link": "/"} className={css.footerLink}>{isMobile ? "Apply" : "Join our Mailing List"}</a>
        <span className={css.dot}>•</span>
        <a href="/sponsor-us#sponsor-us" className={css.footerLink}>Sponsor Us</a>
      </div>
      <div className={css.socials}>
        <a href="https://www.instagram.com/lahacks/?hl=en">
          <img src={InstagramIcon}></img>
        </a>
        <a href="https://x.com/i/flow/login?redirect_after_login=%2Flahacks">
          <img src={TwitterIcon}></img>
        </a>
        <a href="https://www.facebook.com/LAHacks/">
          <img src={FacebookIcon}></img>
        </a>
      </div>
      <p>Made with <img src={HeartEmoji} className={css.emoji}></img> and <img src={StarEmoji} className={css.emoji}></img> from LA Hacks</p>
    </div>
  );
};

