import { useEffect, useMemo, useRef, useState } from "react";
import { PageWrapper, ContentContainer } from "../common/PageWrapper";
import css from "./AboutUsPage.module.scss";
import {
  ButtonMinimal,
  ButtonWithBackground,
  ButtonWithOutline,
} from "../common/Buttons";
import { GalleryProps, Team } from "../googleSheetsTypes";
import { GalleryPhoto } from "../common/GalleryPhoto";
import classNames from "classnames";
import {
  Cloud1,
  Cloud2,
  Constellation,
  DownArrow,
  PlanetSrc,
  RibbonSrc,
  RomanNumeral,
  Squiggle,
  Wheel,
  WheelSrc,
} from "./svgs/svgs";
import useMedia from "use-media";

const TEAMS = [
  "General",
  "Design",
  "Hackops",
  "Tech",
  "Marketing",
  "Sponsorships",
  "Finance",
];

export const AboutUsPage: React.FC<GalleryProps> = ({ getSheetData }) => {
  const teamData = useMemo(
    () => getSheetData("About Us - Teams", 7),
    [getSheetData]
  );
  return (
    <PageWrapper background="linear-gradient(180deg, #0F1722 19.21%, #3F289B 100%)">
      <Background />
      <ContentContainer>
        <TitleSection />
        <InformationSection getSheetData={getSheetData} />
        <GallerySection getSheetData={getSheetData} />
        <BecomeAnOrganizerSection teamData={teamData} />
      </ContentContainer>
      <OrganizerPicturesSection getSheetData={getSheetData} />
      <TeamsSection getSheetData={getSheetData} />
    </PageWrapper>
  );
};

const Background: React.FC = () => {
  return (
    <div className={css.background}>
      <img src={RibbonSrc} alt="Ribbon" className={css.ribbon} />
      <img src={PlanetSrc} alt="Planet" className={css.planet} />
    </div>
  );
};

const TitleSection: React.FC = () => {
  return (
    <div className={css.titleSection}>
      <h1>We are LA Hacks</h1>
      <p>
        To inspire and cultivate the next generation of tech leaders by creating
        a vibrant community where innovation and creativity thrive.
      </p>
      <div className={css.buttonContainer}>
        <a>
          <ButtonWithBackground>Become an organizer</ButtonWithBackground>
        </a>
        <a href="/sponsor-us">
          <ButtonWithOutline>Sponsor Us</ButtonWithOutline>
        </a>
      </div>
      <div className={css.scroll}>Scroll to learn more</div>
    </div>
  );
};

const InformationSection: React.FC<GalleryProps> = ({ getSheetData }) => {
  const teamData = useMemo(
    () => getSheetData("About Us - Information", 4),
    [getSheetData]
  );
  const [activePhotoIndex, setActivePhotoIndex] = useState(0);
  const activePhoto = teamData[activePhotoIndex] ?? null;
  const captionRefs = useRef<Array<HTMLParagraphElement | null>>(
    Array(teamData.length).fill(null)
  );

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const index = Number(entry.target.getAttribute("data-photo-index"));
            setActivePhotoIndex(index);
          }
        });
      },
      { threshold: 0.5 }
    );
    captionRefs.current.forEach((ref) => {
      if (ref) {
        observer.observe(ref);
      }
    });
    return () => observer.disconnect();
  }, [teamData]);

  if (!teamData || !activePhoto) {
    return null;
  }
  return (
    <div className={css.informationSection}>
      <div className={css.photo}>
        <DownArrow
          className={classNames(
            css.informationArrowUp,
            activePhotoIndex === 0 && css.disabled,
            css.desktopOnly
          )}
        />
        <GalleryPhoto id={activePhoto.id} />
        <div>
          <DownArrow
            className={classNames(
              css.informationArrowDown,
              activePhotoIndex === teamData.length - 1 && css.disabled,
              css.desktopOnly
            )}
          />
          <p className={classNames(activePhotoIndex != 0 && css.invisible)}>
            swipe on the text for more!
          </p>
        </div>
      </div>
      <div className={css.captionColumn}>
        {teamData.map((photo, i) => (
          <div key={i} className={css.informationContainer}>
            <p
              ref={(el) => (captionRefs.current[i] = el)}
              data-photo-index={i}
              className={css.caption}
            >
              {photo.caption}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

const GallerySection: React.FC<GalleryProps> = ({ getSheetData }) => {
  const galleryData = useMemo(
    () => getSheetData("About Us - Gallery", 50),
    [getSheetData]
  );
  const isWide = useMedia({ minWidth: "550px" });
  return (
    <div className={css.gallerySection}>
      <h2>1000+ Hacker Event</h2>
      <div className={css.galleryContainer}>
        {isWide
          ? galleryData.map((photo, i) => (
              <div
                style={{
                  gridColumn: `span ${photo.width}`,
                  gridRow: `span ${photo.height}`,
                }}
                key={i}
              >
                <GalleryPhoto id={photo.id} caption={photo.caption} />
              </div>
            ))
          : galleryData.map((photo, i) => (
              <div
                style={{
                  gridRow: `span ${photo.height}`,
                }}
                key={i}
              >
                <GalleryPhoto id={photo.id} caption={photo.caption} />
              </div>
            ))}
      </div>
    </div>
  );
};

const BecomeAnOrganizerSection: React.FC<{ teamData: Team[] }> = ({
  teamData,
}) => {
  return (
    <div className={classNames(css.becomeAnOrganizerSection)}>
      <h2>Become an Organizer (apply by 10/?)</h2>
      <p>
        We are always looking for more talented individuals to join our team!
        Click on a section below to learn more about each organizer team and who
        they are looking for.
      </p>
      <OrganizerSectionDesktop teamData={teamData} />
      <OrganizerSectionMobile teamData={teamData} />
    </div>
  );
};

const OrganizerSectionDesktop: React.FC<{ teamData: Team[] }> = ({
  teamData,
}) => {
  const [team, setTeam] = useState(TEAMS[0]);
  const selectedTeam = teamData.find((t) => t.name === team);
  if (!selectedTeam) {
    return null;
  }
  return (
    <div className={css.desktopOnly}>
      <div className={css.teamSelector}>
        {TEAMS.map((t) => (
          <ButtonMinimal
            key={t}
            onClick={() => setTeam(t)}
            className={team === t ? css.teamSelected : undefined}
          >
            {t}
          </ButtonMinimal>
        ))}
      </div>
      <div className={css.teamContainer}>
        <div className={css.teamDescription}>
          <h5>{selectedTeam.name}</h5>
          <h6>Team Description: </h6>
          <p>{selectedTeam.description}</p>
          <h6>Who We Are Looking For: </h6>
          <p>{selectedTeam.lookingFor}</p>
        </div>
        <div className={css.teamPhotos}>
          {selectedTeam.photoIds.map((photoId, i) => (
            <GalleryPhoto key={i} id={photoId} />
          ))}
        </div>
      </div>
    </div>
  );
};

const OrganizerSectionMobile: React.FC<{ teamData: Team[] }> = ({
  teamData,
}) => {
  const [selectedTeam, setSelectedTeam] = useState<string | null>(null);
  return (
    <div
      className={classNames(css.mobileOnly, css.becomeAnOrganizerSectionMobile)}
    >
      <div className={css.teamContainerMobile}>
        {teamData.map((t) => (
          <TeamDropdown
            key={t.name}
            team={t}
            setTeam={setSelectedTeam}
            isActive={selectedTeam === t.name}
          />
        ))}
      </div>
    </div>
  );
};

const TeamDropdown: React.FC<{
  team: Team;
  setTeam: (team: string | null) => void;
  isActive: boolean;
}> = ({ team, setTeam, isActive }) => {
  const handleClick = () => {
    isActive ? setTeam(null) : setTeam(team.name);
  };
  return (
    <div className={css.teamDropdown}>
      <button
        className={classNames(isActive && css.active)}
        onClick={handleClick}
      >
        {team.name}
        <DownArrow />
      </button>
      {isActive && (
        <div
          className={classNames(css.teamDropdownContent, css.teamDescription)}
        >
          <h5>{team.name}</h5>
          <h6>Team Description: </h6>
          <p>{team.description}</p>
          <h6>Who We Are Looking For: </h6>
          <p>{team.lookingFor}</p>
        </div>
      )}
    </div>
  );
};

const OrganizerPicturesSection: React.FC<GalleryProps> = ({ getSheetData }) => {
  const organizerGalleryPhotos = useMemo(
    () => getSheetData("About Us - Organizer Photos", 20),
    [getSheetData]
  );
  const selectedPhoto = organizerGalleryPhotos[0] ?? null;
  return (
    <div className={css.organizerPictureSection}>
      <div className={css.organizerPictureContainer}>
        <Squiggle className={css.squiggle} />
        <RomanNumeral className={css.romanNumeral} />
        <img
          src={Constellation}
          alt="Constellation"
          className={css.constellation}
        />
        {selectedPhoto && (
          <GalleryPhoto id={selectedPhoto.id} className={css.selectedPhoto} />
        )}
      </div>
      <div className={css.wheelContainer}>
        {/* <Wheel className={css.wheel} /> */}
        <img src={WheelSrc} alt="Wheel" className={css.wheel} />
      </div>
    </div>
  );
};

const TeamsSection: React.FC<GalleryProps> = ({ getSheetData }) => {
  const teamData = useMemo(
    () => getSheetData("About Us - Meet the Team", 30),
    [getSheetData]
  );
  return (
    <div className={css.teamsSection}>
      <div className={css.cloudContainer}>
        <Cloud1 className={css.cloud1} />
        <Cloud2 className={css.cloud2} />
      </div>
      <ContentContainer className={css.infoWrapper}>
        <h2>Our Team</h2>
        <div className={css.meetTheTeamContainer}>
          {teamData.map((teamMember, i) => (
            <TeamMember
              key={i}
              name={teamMember.name}
              role={teamMember.role}
              photoId={teamMember.photoId}
            />
          ))}
        </div>
      </ContentContainer>
    </div>
  );
};

const TeamMember: React.FC<{ name: string; role: string; photoId: string }> = ({
  name,
  role,
  photoId,
}) => {
  return (
    <div className={css.teamMember}>
      <GalleryPhoto id={photoId} className={css.headshot} />
      <div className={css.teamMemberInfo}>
        <h6>{name}</h6>
        <p>{role}</p>
      </div>
    </div>
  );
};
