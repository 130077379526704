import { BrowserRouter, Route, Routes } from "react-router-dom";
import { SponsorPage } from "./sponsor-page/SponsorPage";
import { LandingPage } from "./landing-page/LandingPage";
import { AboutUsPage } from "./about-us-page/AboutUsPage";
import "./App.scss";
import { useGetGalleryInformation } from "./common/utils";

const App = () => {
  const { getSheetData } = useGetGalleryInformation();
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LandingPage getSheetData={getSheetData} />} />
        <Route path="/sponsor-us" element={<SponsorPage getSheetData={getSheetData} />} />
        <Route
          path="/about-us"
          element={<AboutUsPage getSheetData={getSheetData} />}
        />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
