import * as SVGS from "./svgs/svgs";
import React, { FormEvent, useEffect, useRef, useState } from "react";
import { GalleryPhoto } from "../common/GalleryPhoto";
import {
  ContentContainer,
  PageWrapper,
} from "../common/PageWrapper";
import { TitleSection } from "./TitleSection";
import css from "./SponsorPage.module.scss";
import { GalleryProps } from "../googleSheetsTypes";
import { SponsorLogos } from "../common/SponsorLogos";
import { WinningProjects } from "../sponsor-page/WinningProjects";
import { PastDevposts } from "../sponsor-page/PastDevposts";
import { BackgroundElement } from "./TitleSection";
import statsCircle from "../static/sponsor/statsCircle.svg";
import {
  ButtonWithBackground,
  ButtonWithOutline,
} from "../common/Buttons";
import emailjs from '@emailjs/browser';

const useScreenWidth = (breakpoint: number): boolean => {
  const [isMobile, setIsMobile] = useState<boolean>(
    window.innerWidth <= breakpoint
  );

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= breakpoint);

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, [breakpoint]);

  return isMobile;
};

export const SponsorPage: React.FC<GalleryProps> = ({ getSheetData }) => {
  return (
    <PageWrapper background="#181B39">
      <TitleSection />
      <SponsorContentSection getSheetData={getSheetData} />
      <BackgroundElement svg={<SVGS.SpotlightsAndStars />} className={css.spotlightsAndStars} />
    </PageWrapper>
  );
};

const SponsorContentSection: React.FC<GalleryProps> = ({ getSheetData }) => {
  return (
    <div className={css.sponsorContentSection}>
        <StatsSection />
        <ContentContainer>
          <WhySponsorSection />
        </ContentContainer>
        <ContentContainer>
          <SponsorSection getSheetData={getSheetData} />
        </ContentContainer>
        <ContentContainer>
          <WinningProjects></WinningProjects>
        </ContentContainer>
        <ContentContainer>
          <PastDevposts></PastDevposts>
        </ContentContainer>
        <ContentContainer>
          <LearnMoreSection getSheetData={getSheetData}></LearnMoreSection>
        </ContentContainer>
    </div>
  )
}

const StatsSection: React.FC = () => {
  return (
    <div className={css.statsContainer} id="sponsor-us">
      <div className={css.statsCircle} id={css.statsCircleOne}>
        <div className={css.statsText}>
          <h3>1000+</h3>
          <p>participants</p>
        </div>
        <img src={statsCircle} />
      </div>
      <div className={css.statsCircle} id={css.statsCircleTwo}>
        <div className={css.statsText}>
          <h3>200+</h3>
          <p>projects</p>
        </div>
        <img src={statsCircle} />
      </div>
      <div className={css.statsCircle} id={css.statsCircleThree}>
        <div className={css.statsText}>
          <h3>50+</h3>
          <p>sponsors</p>
        </div>
        <img src={statsCircle} />
      </div>
    </div>
  );
};

// const SponsorForm = () => {
//   const [response, setResponse] = useState<string>('');

//   const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
//     event.preventDefault();

//     const form = event.currentTarget as HTMLFormElement;
//     const formData = new FormData(form);

//     try {
//       const res = await fetch('/api/subscribe', {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({
//           email: formData.get('email')?.toString(),
//           fullName: formData.get('fullName')?.toString(),
//           company: formData.get('company')?.toString(),
//           inquiry: formData.get('inquiry')?.toString(),
//         }),
//       });

//       const result = await res.json();
//       setResponse(result.message);
//     } catch (error) {
//       setResponse('Something went wrong!');
//     }
//   };

//   return (
    // <div className={css.sponsorForm}>
    //   <form onSubmit={handleSubmit}>
    //     <div className={css.inputGroup}>
    //       <label>Full Name *</label>
    //       <input name="name" placeholder="Input Text" required />
    //     </div>
    //     <div className={css.inputGroup}>
    //       <label>Company *</label>
    //       <input name="company" placeholder="Input Text" required />
    //     </div>
    //     <div className={css.inputGroup}>
    //       <label>Email *</label>
    //       <input name="email" type="email" placeholder="Input Text" required />
    //     </div>
    //     <div className={css.inputGroup}>
    //       <label>Inquiry *</label>
    //       <input name="inquiry" placeholder="Input Text" required />
    //     </div>
    //     <ButtonWithBackground>Submit</ButtonWithBackground>
    //     {response && <div className={css.responseMessage}>{response}</div>}
    //   </form>
    // </div>
//   );
// };

export const SponsorForm = () => {
  const form = useRef<HTMLFormElement>(null);
  const [formSubmitted, setFormSubmitted] = useState<boolean>(false);

  const sendEmail = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const currentForm = form.current;

    if (currentForm == null) return;

    try {
      const EMAILJS_SERVICE_ID = process.env.REACT_APP_EMAILJS_SERVICE_ID || ''; 
      const EMAILJS_TEMPLATE_ID = process.env.REACT_APP_EMAILJS_TEMPLATE_ID || ''; 
      const EMAILJS_PUBLIC_KEY = process.env.REACT_APP_EMAILJS_PUBLIC_KEY || ''; 
      console.log(EMAILJS_SERVICE_ID)
      await emailjs.sendForm(EMAILJS_SERVICE_ID, EMAILJS_TEMPLATE_ID, currentForm, EMAILJS_PUBLIC_KEY);
      setFormSubmitted(true);
    } catch (error) {
      console.error('Failed to send email:', error);
    }
  };

  return (
    formSubmitted ? <div className={css.sponsorForm}> <div className={css.responseMessage}>Thanks! We'll be in touch soon.</div> </div> :
    <div className={css.sponsorForm}>
      <form ref={form} onSubmit={sendEmail}>
        <div className={css.inputGroup}>
          <label>Full Name *</label>
          <input name="name" placeholder="Input Text" required />
        </div>
        <div className={css.inputGroup}>
          <label>Company *</label>
          <input name="company" placeholder="Input Text" required />
        </div>
        <div className={css.inputGroup}>
          <label>Email *</label>
          <input name="email" type="email" placeholder="Input Text" required />
        </div>
        <div className={css.inputGroup}>
          <label>Inquiry *</label>
          <input name="inquiry" placeholder="Input Text" required />
        </div>
        <ButtonWithBackground>Submit</ButtonWithBackground>
      </form>
    </div>
    
  );
};

const WhySponsorSection: React.FC = () => {
  return (
    <div className={css.whySponsorContainer}>
      <div className={css.whySponsorInfo}>
        <h2>Why Sponsor LA Hacks</h2>
        <p>As a sponsor, you’ll be able to:</p>
        <ul className={css.bulletList}>
          <li>
            Introduce your product or platform to over 1000+ student developers
          </li>
          <li>
            Recruit from an elite pool of software developers and designers
          </li>
          <li>
            Increase your brand’s visibility through LA Hacks’ social media
            channels and events
          </li>
          <li>Integrate your products into hacker creations</li>
          <li>
            Facilitate interaction between participants and your employees &
            brand
          </li>
          <li>Mentor and assist hackers with their projects</li>
        </ul>
        <p>
          Interested in sponsoring? Fill out this contact form, and our team
          will get back to you with more information!
        </p>
      </div>
      <SponsorForm></SponsorForm>
    </div>
  );
};

const SponsorSection: React.FC<GalleryProps> = ({ getSheetData }) => {
  return (
    <div className={css.sponsorContainer}>
      <h2>Past sponsors</h2>
      <div className={css.sponsorLogosContainer}>
        <SponsorLogos getSheetData={getSheetData} />
      </div>
    </div>
  );
};

const LearnMoreSection: React.FC<GalleryProps> = ({ getSheetData }) => {
  const isMobile = useScreenWidth(834);
  const data = getSheetData("Sponsor Page - Learn More", 1);
  const photo = data[0];
  return (
    <div className={css.organizerContainer}>
      <div className={css.callToAction}>
        <div className={css.singlePhoto}>
          { photo ? <GalleryPhoto
            id={photo.id}
            caption={photo.caption}
            className={css.learnMorePhoto}
          /> : null}
        </div>
        <h3> {isMobile
            ? "Interested in becoming an organizer?"
            : "Want to learn more about LA Hacks?"}</h3>
        <a href="/#gallery">
        <ButtonWithOutline>{isMobile ? "Apply now!" : "View Gallery"}</ButtonWithOutline>
        </a>
      </div>
    </div>
  );
};
