import { useState, useEffect } from "react";
import css from "./NavBar.module.scss";
import { useNavigate } from "react-router-dom";
import logo from "../static/logos/lahacks-2025-logo.svg";

export const NavBar: React.FC = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const goToLandingPage = () => navigate("/");
  const goToSponsorPage = () => navigate("/sponsor-us");
  const goToAboutUsPage = () => navigate("/about-us");

  return (
    <div className={`${css.navbar} ${isScrolled ? css.scrolled : ""}`}>
      <a href="/">
        <img src={logo} className={css.navLogo} onClick={goToLandingPage} alt="Logo" />
      </a>
      <div className={css.anchorLinks}>
        <a href="/">
          <button>HOME</button>
        </a>
        <a href="/sponsor-us">
          <button>SPONSOR US</button>
        </a>
        <a href="/about-us">
          <button>ABOUT US</button>
        </a>
      </div>
    </div>
  );
};
