import classNames from "classnames";
import css from "./GalleryPhoto.module.scss";

export interface GalleryPhotoProps {
  id: string;
  caption?: string;
  className?: string;
}

export const GalleryPhoto: React.FC<GalleryPhotoProps> = ({
  id,
  caption,
  className,
}) => {
  return (
    <div
      className={classNames(css.card, className)}
      style={{
        backgroundImage: `url(https://drive.google.com/thumbnail?id=${id}&sz=w1000)`,
      }}
    >
      {caption && <figcaption>{caption}</figcaption>}
    </div>
  );
};
